import { render, staticRenderFns } from "./ReturnRequestModal.vue?vue&type=template&id=2ca6193a&scoped=true&"
import script from "./ReturnRequestModal.vue?vue&type=script&lang=js&"
export * from "./ReturnRequestModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ca6193a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VImg } from 'vuetify/lib/components/VImg';
import { VInput } from 'vuetify/lib/components/VInput';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCol,VContainer,VDataTable,VDialog,VDivider,VFileInput,VImg,VInput,VProgressCircular,VRow,VSelect,VSpacer,VTextarea,VToolbar,VToolbarTitle})
